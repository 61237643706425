.countries {
    text-align: center;

    .radio-container {
        justify-content: space-around;
        align-items: center;
        margin: 0px 10px 20px;
        background: $color-3;
        border-radius: 20px;
        padding: 10px;
        color: $white;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.452);

        input[type="range"] {
            transform: translateY(3px);
            // cursor: pointer;
        }

        label {
            margin-left: 5px;
            margin-right: 10px;
        }

        button {
            margin: 0;
            padding: 2px 6px;
            background-color: $white;
            border: 2px solid;
            border-radius: 10px;
            cursor: pointer;
            transition: 0.2s;
        }

        .allCountries {
            background: $color-4;
            border-color: $color-4;

            &:hover {
                background: $white;
            }
        }

        .oneCountries {
            border-color: $color-4;

            &:hover {
                background: $color-4;
            }
        }

        .modeHard {
            background: $color-2;
            border-color: $color-2;

            &:hover {
                background: $white;
            }
        }

        .modeEasy {
            border-color: $color-2;

            &:hover {
                background: $color-2;
            }
        }

        select {
            padding: 2px 4px;
            border: 2px solid $black;
            border-radius: 10px;
            cursor: pointer;
            transition: 0.2s;
        }

        li {
            margin: 10px 5px 10px;
        }
    }

    input[type="text"] {
        padding: 10px;
        border-radius: 40px;
        font-size: 1.3rem;
        border: 3px solid $color-1;
        outline: none;
        transition: 0.4s;
        text-align: center;
        width: 400px;
        margin-bottom: 20px;

        &:focus {
            background: $color-1;
            border-radius: 4px;
            color: $white;
        }
    }

    button {
        margin: 0 10px 20px;
        padding: 4px 10px;
        background: none;
        border: 2px solid $color-1;
        border-radius: 10px;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
            background: $color-1;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .red-button {
        border-color: $color-3;

        &:hover {
            background: $color-3;
            color: $white;
            animation: shake 0.5s ease-in-out;
        }
    }
}


@keyframes shake {
    0% { transform: translateX(0); }
    10% { transform: translateX(-5px); }
    20% { transform: translateX(10px); }
    30% { transform: translateX(-10px); }
    40% { transform: translateX(0px); }
}
