.logo {
  @include verticalCenter;
  margin: 5px auto;
  width: 250px;
  border-radius: 20px;
  padding: 5px 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.307);
  background-color: $color-3;

  img {
    height: 40px;
  }
  h3 {
    margin-left: 10px;
    color: $color-1;
  }
}
