@font-face {
  font-family: "font-1";
  src: url(../assets/fonts/JosefinSans-Medium.ttf);
}

$font-1: "font-1", sans-serif;

$color-1: #0075ff;
$color-2: rgb(255, 74, 54);
$color-3: #282c34;
$color-4: #33de4f;
$white: rgb(243, 243, 243);
$black: black;

@mixin verticalCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $white;
  font-family: $font-1;
}
li {
  list-style-type: none;
}
